/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import CallSplitIcon from '@mui/icons-material/CallSplit'
import {WidgetContainer, Graphs, InfoTip} from 'components'
import {useDependency, useResource, useFilter, useTooltips} from 'hooks'
import {useSavedFilters} from 'contexts/SavedFiltersContext'
import {useNodes} from 'contexts/NodesContext'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import { Widgets } from './'
import { currentIntervalTime } from 'utils'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import _ from 'lodash'

const DEFAULT_FILTER = {
  regions: ['NI', 'SI'],
  series: ['isl', 'wr']
}

const CURRENT_INTERVAL_REFRESH = 1000 * 60 * 5

const IslandResidualsGraph = (props) => {
  const widget = Widgets['island-residuals-graph']
  const [filter, setFilter] = useFilter(null, 'islandResiduals')
  const savedFilters = useSavedFilters()
  const [demand, demandActions] = useResource('demand')
  const [wind, windActions] = useResource('wind')
  const {nodes: allNodes} = useNodes()
  const windNodes = allNodes.filter(node => node.generationTypeId === 'WIN' && node.generationNode === 'TRUE')
  const [currentInterval, setCurrentInterval] = useState(null)
  const tooltips = useTooltips(widget, true) // enable tooltips by default until user closes them

  const regions  = filter?.regions || []
  const runTypes = ['NRS']
  const filteredSeries = filter?.series || []
  // only show selected series
  const series = _.flattenDeep(regions.map(reg => runTypes.map(rt => {
    const opts = []
    if(filteredSeries.includes('isl')) opts.push(`${reg}_${rt}_ISL`)
    if(filteredSeries.includes('wr') && filteredSeries.includes('isl')) opts.push(`${reg}_${rt}_ISL_windrisk`)
    if(filteredSeries.includes('res')) opts.push(`${reg}_${rt}`)
    if(filteredSeries.includes('wr') && filteredSeries.includes('res')) opts.push(`${reg}_${rt}_windrisk`)
    if(filteredSeries.includes('hvdc')) opts.push(`${reg}_${rt}_HVDC`)
    return opts
  })))

  const updateWidget = () => Promise.all([
    windActions.request('forecast'),
    filter && demandActions.request('residuals', {...filter, runTypes})
  ])

  const [residualsLoading, result, reloadResiduals] = useDependency(updateWidget, [filter])

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)
  const updateCurrentInterval = () => setCurrentInterval(currentIntervalTime())

  useEffect(() => {
    const initialFilter = savedFilters.load(widget) || DEFAULT_FILTER
    setFilter({...initialFilter, ...filter})
  }, [])

  // display current time
  useEffect(() => {
    updateCurrentInterval()
    const _timer = setInterval(updateCurrentInterval, CURRENT_INTERVAL_REFRESH)
    return () => _timer && clearInterval(_timer)
  }, [])

  const items = demand.residuals?.data?.items || []
  const runTimes = items.reduce((acc: any, {runTime}: any) => ([
    ...acc,
    moment(runTime).valueOf(),
  ]), [])
  const residualsUpdatedAt = runTimes.length ? Math.max(...runTimes) : null

  return (
    <WidgetContainer
      widget={widget}
      title="NRS Total Residual inc. HVDC (MW)"
      icon={<CallSplitIcon/>}
      lastUpdatedAt={residualsUpdatedAt}
      lastUpdatedAtAddMinutes={0}
      loading={residualsLoading}
      onWidgetRefresh={reloadResiduals}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      renderFilter={() => <Graphs.IslandResidualsFilter filter={filter} onChange={setFilter} />}
      onFilterSave={() => savedFilters.update(widget, filter)}
      tooltips={tooltips}
      zoom={zoom}
      {...props}
    >
      <Graphs.Residuals
        data={demand.residuals?.data}
        windForecast={wind.forecast?.data}
        windNodes={windNodes}
        series={series}
        zoomCallback={zoomCallback}
        currentInterval={currentInterval}
        tooltips={tooltips}
      >
        <InfoTip tooltips={tooltips}>
          <Typography>
            Residual is the difference between energy offered into the market
            and the forecasted demand. Island Residual is the sum of residual energy
            for the selected island, plus the residual available via the HVDC inter-island cable.
          </Typography>
          <Typography>
            Wind risk is an em6 estimate for the potential largest possible
            drop in actual wind generation against forecast.
            The data on this graph does not relate to, or help determine,
            Transpower's operational policy and is for information only.
          </Typography>
        </InfoTip>
      </Graphs.Residuals>
    </WidgetContainer>
  )
}

export default IslandResidualsGraph