/** @jsxImportSource @emotion/react */
import React, {useEffect, useMemo, useState} from 'react'
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps"
import Marker, { DropShadow } from './Marker'
import NZGrid from './data/NZGrid.json'
import PriceRanges from './data/PriceRanges.json'
import { RangeLegend } from 'components/maps'
import {iconBasedOnChange, positionNzMiddle, trailingZero} from 'utils'
import useZoom from './useZoom'

const SERIES = PriceRanges.reduce((acc, curr) => ({...acc, [curr.id]: curr}), {})

const NodePrice = ({data, nodes, zoomCallback}) => {

  const [visibleSeries, setVisibleSeries] = useState(Object.keys(SERIES))
  const [orderedNodes, setOrderedNodes] = useState(nodes)

  const priceRange = price => price ? PriceRanges.find(range => range.max > price || !range.max) : null

  const itemsByNodeId = useMemo(() => data?.items?.reduce((acc, curr) => ({
    ...acc,
    [curr.nodeId]: {...curr, priceRange: priceRange(curr.price)}
  }), {}) || {}, [JSON.stringify(data)])

  const {position, zoomableGroupProps} = useZoom({coordinates: [173, -41.1], zoomCallback})

  useEffect(() => {
    setOrderedNodes(nodes)
  }, [JSON.stringify(nodes)])

  const handleMarkerMouseOver = node => {
    // move node to the back so it gets drawn last on top of all the others
    const updated = [...orderedNodes.filter(({nodeId}) => nodeId !== node.nodeId), node]
    setOrderedNodes(updated)
  }

  return (
    <>
      <div className='map' css={styles.container}>
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{
            center: [0,0],
            scale: 2070 * position.zoom
          }}
          fill='white'
        >
          <defs>
            <DropShadow/>
          </defs>

          <ZoomableGroup {...zoomableGroupProps}>
            <Geographies geography={NZGrid}>
              {({ geographies }) =>
                geographies.map((geo) =>
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#fff"
                  />
                )
              }
            </Geographies>

            {orderedNodes.map(node => {
              const {nodeId, longitude, latitude} = node
              const item = itemsByNodeId[nodeId]
              const visible = !item || visibleSeries.includes(String(item?.priceRange?.id))
              const text = item?.price ? `$${trailingZero(item?.price)}` : ' - '
              const color = item?.priceRange?.color
              const textColor = item?.priceRange?.textcolor || '#fff'
              const {left} = positionNzMiddle(latitude, longitude)

              return (
                item && <Marker
                          key={nodeId}
                          coordinates={[longitude, latitude]}
                          dx={left ? -150 : 100}
                          title={nodeId}
                          text={text}
                          visible={visible}
                          labelStyle={{fill: color}}
                          markerStyle={{stroke: color, fill: '#fff'}}
                          textStyle={{fill: textColor}}
                          connectorStyle={{stroke: color}}
                          icon={iconBasedOnChange(item?.price, item?.priceChange, textColor)}
                          onMouseOver={() => handleMarkerMouseOver(node)}
                        />
              )})}

          </ZoomableGroup>
        </ComposableMap>
      </div>
      <RangeLegend series={SERIES}/>
    </>
  )
}

const styles = {
  container: {
  },
}

export default NodePrice