/** @jsxImportSource @emotion/react */
import React, {useMemo, useRef} from 'react'
import { FormControlLabel, Checkbox } from "@mui/material"
import {InfoTooltip} from 'components'

const LegendLabel = ({label, strokeDasharray, tooltips, tooltip, color}) => {
  const body = strokeDasharray ? <span style={{textDecoration: 'underline dashed', textDecorationColor: color, textUnderlineOffset: 5}}>{label}</span> : label
  return <>{body}<InfoTooltip tooltips={tooltips} title={tooltip} placement="top" arrow/></>
}


const Legend = ({series, visible, onSeriesToggle, tooltips=[]}) => {

  const seriesKeys = Object.keys(series)

  const handleVisibilityToggle = (key, vis) => {
    const updated = vis ? visible.concat(key) : visible.filter(s => s !== key)
    onSeriesToggle(updated)
  }

  const legendRef = useRef(null)

  const minWidth = useMemo(() => {
    // calculate the width of the largest legend item as minWidth for all
    const children = Array.from(legendRef.current?.children || [])
    return Math.max(...children.map(child => child.clientWidth))
  }, [legendRef.current?.children?.length, JSON.stringify(series)])

  return (
    <div className='legend' css={styles.legend} ref={legendRef}>
      {seriesKeys.map((key, idx) => {
        const { label, color, strokeDasharray, tooltip } = series[key]
        const vis = visible.includes(key)
        return (
          <FormControlLabel
            key={idx}
            className="legend-item"
            style={minWidth ? {minWidth} : {}}
            css={styles.item}
            control={
              <Checkbox
                checked={vis}
                onChange={() => handleVisibilityToggle(key, !vis)}
                style={{color, ...strokeDasharray ? {'& svg': {border: `1px dashed ${color}`}} : {}}}
              />
            }
            label={
              <LegendLabel
                label={label}
                strokeDasharray={strokeDasharray}
                tooltips={tooltips}
                tooltip={tooltip}
                color={color}
              />
              }
          />
          )
      })}
    </div>
  )
}

const styles = {
  legend: {
    width: '100%',
    marginTop: 20,
    paddingLeft: 35,
    display: 'flex',
    flexBasis: 'auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  item: {
    paddingRight: 10,
  },
}

export default Legend