/** @jsxImportSource @emotion/react */
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { DatePicker } from 'components'
import Grid from '@mui/material/Grid'
import useAuthorization from '../../hooks/useAuthorization'

export const RESERVE_REPORT_TYPES = [
  {id: 'aggregated', label: 'Aggregated Reserves'},
  {id: 'source', label: 'Reserve Amount by Source - restricted'},
  {id: 'extract', label: 'Reserves Extract - restricted'},
  {id: 'prices', label: 'Reserve Prices'},
]

export const LOCATIONS = [
  {id: '', label: 'All NZ'},
  {id: 'NI', label: 'North Island'},
  {id: 'SI', label: 'South Island'},
]

export const POWER_TYPES = [
  {id: '', label: 'All Power Types'},
  {id: 'I', label: 'Interruptible'},
  {id: 'R', label: 'Partly Loaded Spinning Reserve'},
  {id: 'T', label: 'Tail Water Depressed Reserve'},
]

export const RESERVE_RUN_TYPES = [
  {id: '', label: 'All'},
  {id: 'NRS', label: 'NRS'},
  {id: 'PRS', label: 'PRS'},
  {id: 'WDS', label: 'WDS'},
  {id: 'Interim', label: 'Interim'},
  {id: 'Final', label: 'Final'},
]

const ReservesFilter = ({filter, onChange}) => {

  const { isReportPermitted } = useAuthorization()

  const handleChange = att => event => {
    onChange({...filter, [att]: event.target.value})
  }

  const { reserveReportType, location, powerType, reserveRunType, reserveType, from, to } = filter
  const permittedReserveReportTypes = RESERVE_REPORT_TYPES.filter(rrt => isReportPermitted('reserves', rrt.id))

  return (
    <>
      <Grid item xs={12} md={6}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="reserve-report-type">Report Type</InputLabel>
          <Select
            labelId="reserve-report-type"
            value={reserveReportType || ''}
            label="Report Type"
            onChange={ handleChange('reserveReportType') }
          >
            {permittedReserveReportTypes.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={4}>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink id="location">Location</InputLabel>
          <Select
            displayEmpty
            labelId="location"
            value={location || ''}
            label="Location"
            onChange={ handleChange('location') }
          >
            {LOCATIONS.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>

      {reserveReportType !== 'prices' &&
      <Grid item xs={12} md={4}>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink id="power-type">Power Type</InputLabel>
          <Select
            displayEmpty
            labelId="power-type"
            value={powerType || ''}
            label="Power Type"
            onChange={ handleChange('powerType') }
          >
            {POWER_TYPES.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>}

      {reserveReportType === 'prices' &&
      <Grid item xs={12} md={8}>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink id="run-type">Run Type</InputLabel>
          <Select
            displayEmpty
            labelId="run-type"
            value={reserveRunType || ''}
            label="Run Type"
            onChange={ handleChange('reserveRunType') }
          >
            {RESERVE_RUN_TYPES.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>}

      <Grid item xs={12} md={6}>
        <DatePicker
          label="From"
          value={from}
          onChange={handleChange('from')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          label="To"
          value={to}
          onChange={handleChange('to')}
          fullWidth
        />
      </Grid>
    </>
  )

}

const styles = {
}

export default ReservesFilter