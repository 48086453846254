/** @jsxImportSource @emotion/react */
import React from 'react'
import Grid from '@mui/material/Grid'
import { PageContainer } from 'components'
import { StylesObject } from "types"
import { Widget } from '../../widgets'

//
// The foyer screens at Transpower are 1920 x 1080
// Widget aspect ratios & margins are customised to optimise screen space
//
const Foyer = () => {

  return (
    <PageContainer css={styles.page}>
      <Grid container spacing={2} css={styles.container}>

        <Widget
          id="regional-price-map"
          hideActions
          size={{xs: 5}}
          mapProps={{scale: 3000, height: 880, markerProps: {titleFontSize: 18, height: 50, width: 135, padding: 7}}}
        />

        <Grid container item spacing={2} xs={7}>
          <Widget id="carbon-dial" hideActions size={{xs: 6}}/>
          <Widget id="previous-day-prices-graph" hideActions size={{xs: 6}}/>
          <Widget id="stacked-generation-graph" hideActions size={{xs: 6}}/>
          <Widget id="wind-forecast-graph" hideActions size={{xs: 6}}/>
        </Grid>
      </Grid>
    </PageContainer>
  );
}

const styles = {
  page: {
    // hide scrollbars
    maxHeight: '100vh',
    overflow: 'hidden',
    padding: 0,
  },
  container: {
    '& .widget-container .MuiCardContent-root': {
      paddingBottom: 15,
    },
    '& .regional-price-map': {
      '& .map': {},
      '& .legend': {
        display: 'flex',
        alignItems: 'flex-end',
      },
    },
    '& .previous-day-prices-graph': {
      '& .graph': {
        aspectRatio: '11/6 auto',
      },
    },
    '& .stacked-generation-graph': {
      '& .graph': {
        aspectRatio: '10/6 auto',
      },
    },
    '& .wind-forecast-graph': {
      '& .graph': {
        aspectRatio: '10/6 auto',
      },
    },
    '& .carbon-dial': {
      '& .MuiCardContent-root': {
        // the carbon dial is a half circle but Recharts seems to keep (empty) bottom space for the full circle
        paddingBottom: 0,
      },
      '& .graph': {
        aspectRatio: 'unset',
      },
      '& .recharts-wrapper': {
        // scale the svg
        maxHeight: 360,
      }
    },
    '& .legend': {
      marginTop: 5,
      '& .MuiFormControlLabel-root': {
        marginRight: 30,
      },
      '& .MuiCheckbox-root': {
        padding: 4
      }
    }
  }
} as StylesObject

export default Foyer