/** @jsxImportSource @emotion/react */
import React, {useState, useEffect, useMemo} from 'react'
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps"
import Marker, { DropShadow } from './Marker'
import { RangeLegend } from 'components/maps'
import NZGrid from './data/NZGrid.json'
import LoadRanges from './data/LoadRanges.json'
import {iconBasedOnChange, positionNzMiddle, round} from 'utils'
import useZoom from './useZoom'

const SERIES = LoadRanges.reduce((acc, curr) => ({...acc, [curr.id]: curr}), {})

const NodeLoad = ({data, nodes, zoomCallback}) => {

  const [visibleSeries, setVisibleSeries] = useState(Object.keys(SERIES))
  const [orderedNodes, setOrderedNodes] = useState(nodes)

  const loadRange = load => load ? LoadRanges.find(range => range.max > load || !range.max) : null

  const itemsByNodeId = useMemo(() => data?.items?.reduce((acc, curr) => ({
    ...acc,
    [curr.nodeId]: {...curr, load: curr.scadaMwCurrent, loadRange: loadRange(curr.pct7Day)}
  }), {}) || {}, [JSON.stringify(data)])

  const {position, zoomableGroupProps} = useZoom({coordinates: [173, -41.1], zoomCallback})

  useEffect(() => {
    setOrderedNodes(nodes)
  }, [JSON.stringify(nodes)])

  const handleMarkerMouseOver = node => {
    // move node to the back so it gets drawn last on top of all the others
    const updated = [...orderedNodes.filter(({nodeId}) => nodeId !== node.nodeId), node]
    setOrderedNodes(updated)
  }

  return (
    <>
      <div className='map' css={styles.container}>
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{
            center: [0,0],
            scale: 2070 * position.zoom
          }}
          fill='white'
        >
          <defs>
            <DropShadow/>
          </defs>

          <ZoomableGroup {...zoomableGroupProps}>
            <Geographies geography={NZGrid}>
              {({ geographies }) =>
                geographies.map((geo) =>
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#fff"
                  />
                )
              }
            </Geographies>

            {orderedNodes.map(node => {
              const {nodeId, longitude, latitude} = node
              const item = itemsByNodeId[nodeId]
              const visible = !item || visibleSeries.includes(String(item?.loadRange?.id))
              const text = item?.load ? `${round(item?.load)} MW` : ' - '
              const color = item?.loadRange?.color
              const textColor = item?.loadRange?.textcolor || '#fff'
              const {left} = positionNzMiddle(latitude, longitude)

              return (
                item && <Marker
                          key={nodeId}
                          coordinates={[longitude, latitude]}
                          dx={left ? -150 : 100}
                          title={nodeId}
                          text={text}
                          visible={visible}
                          labelStyle={{fill: color, width: 126}}
                          markerStyle={{stroke: color, fill: '#fff'}}
                          textStyle={{fill: textColor}}
                          connectorStyle={{stroke: color}}
                          icon={iconBasedOnChange(item?.load, item?.mwChange, textColor)}
                          onMouseOver={() => handleMarkerMouseOver(node)}
                        />
              )})}

          </ZoomableGroup>
        </ComposableMap>
      </div>
      <RangeLegend
        info="Current load vs. this trading period’s 7-day peak"
        series={SERIES}
      />
    </>
  )
}

const styles = {
  container: {
  },
}

export default NodeLoad