/** @jsxImportSource @emotion/react */
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { DatePicker, NodePicker } from 'components'
import Grid from '@mui/material/Grid'

export const PRICE_TYPES = [
  {id: 'average', label: 'Average'},
  {id: 'market', label: 'Market'},
]

export const PRICE_RUN_TYPES = [
  {id: 'V', label: 'Provisional'},
  {id: 'T', label: 'Interim'},
  {id: 'F', label: 'Final'},
  {id: 'PR', label: 'Price Responsive - Latest'},
  {id: 'NR', label: 'Non Responsive - Latest'},
]

const PriceFilter = ({filter, onChange}) => {

  const handleChange = att => event => {
    onChange({...filter, [att]: event.target.value})
  }

  const { priceType, priceRunType, nodes, from, to } = filter

  return (
    <>
      <Grid item xs={12} md={6}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="price-type">Type</InputLabel>
          <Select
            labelId="price-type"
            value={priceType || ''}
            label="Type"
            onChange={ handleChange('priceType') }
          >
            {PRICE_TYPES.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      {priceType === 'market' &&
        <Grid item xs={12} md={6}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="run-type">Run Type</InputLabel>
            <Select
              labelId="run-type"
              value={priceRunType || ''}
              label="Type"
              onChange={ handleChange('priceRunType') }
            >
              {PRICE_RUN_TYPES.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>}
      <Grid item xs={12}>
        <NodePicker
          value={nodes}
          nodeTypes={['pricingNode']}
          onChange={ handleChange('nodes') }
          multiple
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          label={priceType === "average" ? "From" : "Trading Date"}
          value={from}
          onChange={handleChange('from')}
          fullWidth
        />
      </Grid>
      {priceType === "average" &&
        <Grid item xs={12} md={6}>
          <DatePicker
            label="To"
            value={to}
            onChange={handleChange('to')}
            fullWidth
          />
        </Grid>
      }
    </>
  )

}

const styles = {
}

export default PriceFilter