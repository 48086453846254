import React, {useContext, useState} from "react"

//
// Persist wiget preferences state into localstorage
//

const LOCAL_STORAGE_KEY = 'widgetPreferences'

const WidgetPreferencesContext = React.createContext(undefined)

export const WidgetPreferencesProvider = ({ children }) => {

  const initialStateDefault = {}
  let initialState

  try{
    initialState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || initialStateDefault
  } catch(e){
    initialState = initialStateDefault
  }

  const [preferences, setPreferences] = useState(initialState)

  const updatePreferences = preferences => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(preferences))
    setPreferences(preferences)
  }

  const updateWidgetPreference = (widget, preference) => {
    const current = preferences[widget.id] || {}
    updatePreferences({...preferences, [widget.id]: {...current, ...preference}})
  }

  const value = {
    preferences,
    update: updateWidgetPreference,
    load: (widget) => preferences[widget.id] || {},
  }
  return (
    <WidgetPreferencesContext.Provider value={value}>
      {children}
    </WidgetPreferencesContext.Provider>
  )
}

export const useWidgetPreferences = () => {
  const preferences = useContext(WidgetPreferencesContext)
  if (!preferences) {
    console.warn("WidgetPreferencesContext must be provided in order to use it")
    return {}
  }

  return preferences
}