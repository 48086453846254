import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useLocation } from 'react-router-dom'

const useScreenInfo = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const location = useLocation()
  const isFoyer = location.pathname === '/foyer'
  const isWidget = location.pathname.startsWith('/widgets/')
  const hideNav = isWidget
  const hideFooter = isFoyer || isWidget

  return { isMobile, isFoyer, isWidget, hideNav, hideFooter }
}

export default useScreenInfo