/** @jsxImportSource @emotion/react */
import React from 'react'

const RangeLegend = ({series, info}) => {

  const seriesKeys = Object.keys(series)

  return (
    <div className='legend' css={styles.legend}>
      <div css={styles.info}> {info} </div>
      <div css={styles.series}>
        {seriesKeys.map((key, idx) => {
          const { label, color } = series[key]
          return (
            <div
              key={idx}
              className="legend-item"
              style={{borderColor: color}}
              css={styles.item}
            >
              {label}
            </div>)
        })}
      </div>
    </div>
  )
}

const styles = {
  legend: {
    marginTop: 5,
    paddingLeft: 30,
    paddingRight: 30,
  },
  info: {
    fontSize: 12,
    minHeight: 20, // align maps with/without range legend info text
  },
  series: {
    width: '100%',
    display: 'flex',
  },
  item: {
    flex: 1,
    paddingTop: 3,
    borderTopWidth: 8,
    borderTopStyle: 'solid',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600,
  },
}

export default RangeLegend