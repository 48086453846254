/** @jsxImportSource @emotion/react */
import React, { useMemo, useState, useEffect, useRef } from 'react'
import Slider from '@mui/material/Slider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import IconButton from '@mui/material/IconButton';
import { round } from 'utils'
import _ from 'lodash'

import {
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import Legend from './Legend'
import GenerationHistory from './GenerationHistory'
import { TooltipList, TooltipListItem, TooltipHeader } from './Tooltip'
import { useSeries, useScreenInfo } from 'hooks'

const WINDOW_SIZE = 15 // number of sites shown in Brush window

const SERIES = {
  "scadaMwCurrent": {
    label: "Current Generation",
    color: '#00a9a5',
    position: 0
  },
  'availableCapacity': {
    label: "Remaining",
    color: '#CBE612',
    position: 1
  },

}

const CustomTooltip = ({ active, series, payload, historicGeneration, minWidth }: any) => {
  if (active && payload?.length && series?.length) {
    const data = payload[0].payload ?? {}
    const historic = historicGeneration.filter(({nodeId}) => nodeId === data.nodeId)
    return (
      <div css={styles.customTooltip} style={{minWidth}}>
        <TooltipList>
          <TooltipHeader label={`${data.nodeName} (${round(data.generationCapacityMw)} MW) - ${data.generatorDescription}`} />
            <TooltipListItem
              label={data.generationTypeName}
              color={SERIES['scadaMwCurrent'].color}
              value={`${round(data.scadaMwCurrent)} MW`}
            />
            <TooltipListItem
              label="Remaining"
              color={SERIES['availableCapacity'].color}
              value={`${round(data.availableCapacity)} MW`}
            />
            {historic.length ?
            <TooltipListItem
              label="Past 7 days Generation (MW)"
              color="#6388d7"
            /> : null}
        </TooltipList>
        {historic.length ?
          <GenerationHistory historicGeneration={historic}/> : null}
      </div>
    )
  }
}

const GenerationCapacity = ({ width = '100%', height = '100%', generationNodes, currentGeneration, historicGeneration, filter }) => {

  const currentGenerationByNodeId = _.groupBy(currentGeneration, "nodeId")

  const applyFilters = ({ generationTypeName, generatorSize }) => {
    const { generatorType: selectedTypes, generatorSize: selectedSize } = filter
    if (!!selectedTypes && !selectedTypes.includes(generationTypeName)) return false
    if (!!selectedSize && !selectedSize.includes(generatorSize)) return false
    return true
  }

  const getCapacitySize = (maxCapacity) => {
    if (maxCapacity >= 300) return "extraLarge"
    if (maxCapacity >= 200) return "large"
    if (maxCapacity >= 100) return "medium"
    return "small"
  }

  const getScadaMwCurrentForNode = (nodeId) => {
    const scadaMwCurrent = currentGenerationByNodeId[nodeId]?.[0]?.scadaMwCurrent || 0
    return scadaMwCurrent >= 0 ? scadaMwCurrent : 0
  }

  const formatData = ({ nodeId, generationCapacityMw, ...rest }) => {
    const generatorSize = getCapacitySize(generationCapacityMw)
    const scadaMwCurrent = getScadaMwCurrentForNode(nodeId)
    const availableCapacity = Math.max(0, generationCapacityMw - scadaMwCurrent)
    return {
      nodeId,
      generationCapacityMw,
      scadaMwCurrent,
      generatorSize,
      availableCapacity,
      ...rest
    }
  }

  const data = useMemo(() =>
    generationNodes.map(formatData)
                  .filter(item => applyFilters(item))
                  .sort((a, b) => b.generationCapacityMw - a.generationCapacityMw)
    ,[generationNodes, currentGenerationByNodeId])

  useEffect(() => {
    setSlider([0, Math.min(data.length, WINDOW_SIZE - 1)])
  }, [JSON.stringify(data)])

  const handleDecrement = () => {
    if (slider[0] <= 0) return
    setSlider([slider[0] - 1, slider[1]])
  }
  const handleIncrement = () => {
    if (slider[1] >= data.length) return
    setSlider([slider[0], slider[1] + 1])
  }
  const endIndex = Math.min(data.length)
  const [graph, actions] = useSeries(SERIES, data)
  const [slider, setSlider] = useState([0, 0])

  const minDistance = 10;

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], endIndex - minDistance);
        setSlider([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setSlider([clamped - minDistance, clamped]);
      }
    } else {
      setSlider(newValue)
    }
  }

  // Tooltip width calcultions
  const { isMobile } = useScreenInfo()
  const chartRef = useRef(null)
  const chartWidth = chartRef.current?.clientWidth
  // scale the tooltips so that the historic generation graph is bigger on full screen
  const tooltipMinWidth = chartWidth ? (isMobile ? chartWidth : chartWidth - 0.35*chartWidth) : 0
  // on mobile we position the tooltips fixed at full width
  const tooltipPosition = isMobile ? {x: 0, y: 0} : undefined

  return (
    <>
      <ResponsiveContainer width={width} height={height} ref={chartRef}>
        <BarChart
          data={graph.data.slice(slider[0], slider[1])}
          stackOffset="sign"
          barGap={0}
          barCategoryGap={0}
          margin={{ top: 40, bottom: 70 }}
        >
          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '12px',
            }}
            dataKey="nodeName"
            type="category"
            domain={graph.xDomain}
            axisLine={false}
            tickLine={false}
            angle={-40}
            textAnchor="end"
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '12px',
            }}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={[0, graph.yDomain[1]]}
          />

          <Tooltip
            wrapperStyle={{ zIndex: 2 }}
            position={tooltipPosition}
            content={args => CustomTooltip({ ...args, series: graph.visibleSeries, historicGeneration, minWidth: tooltipMinWidth })}
          />

          {graph.visibleSeries.toSorted((a, b) => SERIES[a].position - SERIES[b].position).map(g =>
            <Bar
              key={g}
              barSize={20}
              dataKey={g}
              stroke={SERIES[g].color}
              fill={SERIES[g].color}
              stackId="capacity"
            />
          )}
        </BarChart>
      </ResponsiveContainer>
      {data.length > WINDOW_SIZE &&
        <div css={styles.sliderContainer}>
          <IconButton css={styles.decrementButton} onClick={handleDecrement}>
            <ChevronLeftIcon />
          </IconButton>
          <Slider
            getAriaLabel={() => "Sites"}
            valueLabelDisplay="auto"
            step={1}
            value={slider}
            min={0}
            max={endIndex}
            disableSwap
            onChange={handleChange}
          />
          <IconButton css={styles.incrementButton} onClick={handleIncrement}>
            <ChevronRightIcon />
          </IconButton>
        </div>
      }
      <Legend
        series={SERIES}
        visible={graph.visibleSeries}
        onSeriesToggle={actions.setVisibleSeries}
      />
    </>
  )
}

const styles = {
  incrementButton: {
    marginLeft: 20
  },
  decrementButton: {
    marginRight: 20
  },
  sliderContainer:{
    display: "flex",
    alignItems: "center",
    marginTop:10
  },
  customTooltip: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    padding: 10,
    borderRadius: 10,
    '& .tooltip-list': {
      backgroundColor: 'unset',
    }
  }
}

export default GenerationCapacity