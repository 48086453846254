import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { isProduction } from 'utils'

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/tokens/sign_in"             component={Containers.Token.SignIn}/>
      <Route exact path="/tokens/forgot"              component={Containers.Token.Forgot}/>
      <Route exact path="/reset-password"             component={Containers.Token.Reset}/>

      <Route exact path="/confirm_forgot_password"    component={Containers.Status.ForgotPassword} />
      <Route exact path="/foyer"                      component={Containers.Dashboard.Foyer} />
      <Route exact path="/widgets/:id"                component={Containers.Dashboard.WidgetById} />
      <Route exact path="/"                           component={Containers.Dashboard.Free}/>
      <Route component={() =>  <Redirect to={"/tokens/sign_in"}/>}/>
    </Switch>
  )
}

export default Routes
