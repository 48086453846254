import { useMemo } from 'react'
import { useTokens } from "contexts/TokensContext";
import _ from 'lodash'

export enum AccessGroups {
  EM6_PUBL = 'EM6_PUBL',
  EM6_RCPD = 'EM6_RCPD',
  EM6_PRIC = 'EM6_PRIC',
  EM6_SCAD = 'EM6_SCAD',
  EM6_RSRV = 'EM6_RSRV',
  EM6_NTWK = 'EM6_NTWK',
  EM6_CARB = 'EM6_CARB',
  EM6_RT   = 'EM6_RT',
}

// permissions mapping for reports and report (sub) types
// permittedReports returns the report options which the user can select in the reports widget
// isReportPermitted can further narrow down the options available within one report
export const ReportGroups = {
  scada:     {all: [AccessGroups.EM6_SCAD]},
  price:     {all: [AccessGroups.EM6_PRIC]},
  reserves:  {all: [AccessGroups.EM6_RSRV], prices: [AccessGroups.EM6_PRIC, AccessGroups.EM6_PUBL, AccessGroups.EM6_RSRV]},
  arc:       {all: [AccessGroups.EM6_NTWK]},
  emissions: {all: [AccessGroups.EM6_CARB]},
  rcpd:      {all: [AccessGroups.EM6_RCPD]},
}

const useAuthorization = () => {
  const [tokens,] = useTokens()

  const user: any = tokens.currentUser || {}
  const groups = user.groups || []

  const permittedReportTypes = report => Object.keys(ReportGroups[report]).filter(key => _.intersection(groups, ReportGroups[report][key]).length)
  const isReportPermitted = (report, reportType=null) => {
    const permitted = permittedReportTypes(report)
    return reportType ? permitted.includes('all') || permitted.includes(reportType) : !!permitted.length
  }
  const permittedReports = Object.keys(ReportGroups).filter(key => isReportPermitted(key))

  const isWidgetPermitted = widget => {
    if (!widget) return false
    if (widget.permissionsCheck) return widget.permissionsCheck(user) // custom permissions check
    if (!widget.groups?.length) return true
    return widget.groups.every(g => groups.includes(g))
  }

  return useMemo(() => ({
    user,
    groups,
    permittedReports,
    isWidgetPermitted,
    isReportPermitted,
  }), [user])
}

export default useAuthorization