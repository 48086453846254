/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import AltRouteOutlinedIcon from '@mui/icons-material/AltRouteOutlined'
import {WidgetContainer, Graphs} from 'components'
import {useDependency, useResource, useFilter} from 'hooks'
import {useSavedFilters} from 'contexts/SavedFiltersContext'
import {useNodes} from 'contexts/NodesContext'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import { Widgets } from './'
import { currentIntervalTime } from 'utils'
import moment from 'moment'

const DEFAULT_FILTER = {
  regions: ['NZ', 'NI', 'SI'],
  runTypes: ['NRS']
}

const CURRENT_INTERVAL_REFRESH = 1000 * 60 * 5

const ResidualsGraph = (props) => {
  const widget = Widgets['residuals-graph']
  const [filter, setFilter] = useFilter(null, 'residuals')
  const savedFilters = useSavedFilters()
  const [demand, demandActions] = useResource('demand')
  const [wind, windActions] = useResource('wind')
  const {nodes: allNodes} = useNodes()
  const windNodes = allNodes.filter(node => node.generationTypeId === 'WIN' && node.generationNode === 'TRUE')
  const [currentInterval, setCurrentInterval] = useState(null)
  const tooltips = useState(false)

  const regions  = filter?.regions || []
  const runTypes = filter?.runTypes || []
  const series = regions.map(reg => runTypes.map(rt => `${reg}_${rt}`)).flat()
  // only show wind risk for one region/runType to avoid clutter
  if (series.length === 1) series.push(`${series[0]}_windrisk`)

  const updateWidget = () => Promise.all([
    windActions.request('forecast'),
    filter && Object.keys(filter).length && demandActions.request('residuals', filter)
  ])

  const [residualsLoading, result, reloadResiduals] = useDependency(updateWidget, [filter])

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)
  const updateCurrentInterval = () => setCurrentInterval(currentIntervalTime())

  useEffect(() => {
    const initialFilter = savedFilters.load(widget) || DEFAULT_FILTER
    setFilter({...initialFilter, ...filter})
  }, [])

  // display current time
  useEffect(() => {
    updateCurrentInterval()
    const _timer = setInterval(updateCurrentInterval, CURRENT_INTERVAL_REFRESH)
    return () => _timer && clearInterval(_timer)
  }, [])

  const items = demand.residuals?.data?.items || []
  const runTimes = items.reduce((acc: any, {runTime}: any) => ([
    ...acc,
    moment(runTime).valueOf(),
  ]), [])
  const residualsUpdatedAt = runTimes.length ? Math.max(...runTimes) : null

  return (
    <WidgetContainer
      widget={widget}
      title="Residual Schedules (MW)"
      icon={<AltRouteOutlinedIcon/>}
      lastUpdatedAt={residualsUpdatedAt}
      lastUpdatedAtAddMinutes={0}
      loading={residualsLoading}
      onWidgetRefresh={reloadResiduals}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      renderFilter={() => <Graphs.ResidualsFilter filter={filter} onChange={setFilter} />}
      onFilterSave={() => savedFilters.update(widget, filter)}
      tooltips={tooltips}
      zoom={zoom}
      {...props}
    >
      <Graphs.Residuals
        data={demand.residuals?.data}
        windForecast={wind.forecast?.data}
        windNodes={windNodes}
        series={series}
        zoomCallback={zoomCallback}
        currentInterval={currentInterval}
        tooltips={tooltips}
      />
    </WidgetContainer>
  )
}

export default ResidualsGraph