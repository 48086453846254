/** @jsxImportSource @emotion/react */
import React from 'react'
import { StylesObject } from "types"
import { Widgets, Widget } from '../../widgets'
import { useParams } from 'react-router-dom'

//
// 3rd party websites can embed free widgets in an iFrame
// This container renders a single widget by id without headers/footers
//

const WidgetById = () => {

  const { id }: any = useParams()
  const validWidget = Widgets[id] && !Widgets[id].groups.length // allow all free widgets to be embedded for now

  return validWidget ?
    <Widget id={id} disableFullScreen showAttribution/> :
    <h1>{id} is not a valid widget id</h1>
}

const styles = {
} as StylesObject

export default WidgetById