/** @jsxImportSource @emotion/react */
import React, {useMemo} from 'react'
import moment from 'moment'
import { round } from 'utils'
import {
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ReferenceArea,
  Area,
} from 'recharts'
import { TooltipList, TooltipListItem, TooltipHeader, formatTimestamp } from './Tooltip'
import Legend from './Legend'
import { useSeries } from 'hooks'
import { InfoTip } from 'components'

const SERIES = {
  'generationmw': {
    label: 'Actual generation',
    color: '#FFD52D',
  },
  'percentile50': {
    label: '50th percentile',
    color: '#fff',
    tooltip: "Based off weather forecasts, there is a 50% likelihood that power generated at NI wind farms will be either above or below this line",
  },
  'offered': {
    label: 'Offered wind gen.',
    color: '#CBE612',
    tooltip: "Aggregated NI Wind Farm forecasted generation",
  },
  'percentile90': {
    label: '90th percentile',
    color: '#B1E9E6',
    strokeDasharray: "5 4",
    tooltip: "Based off weather forecasts, there is a 90% likelihood that power generated at NI wind farms will be below this line",
  },
  'percentile10': {
    label: '10th percentile',
    color: '#00A9A5',
    strokeDasharray: "5 4",
    tooltip: "Based off weather forecasts, there is a 10% likelihood that power generated at NI wind farms will be below this line (90% chance it will be above)",
  },
  'shortfall': {
    label: 'Discrepancy',
    color: '#ED8B00',
    tooltip: "50th percentile minus wind offered",
  },
}

const SERIES_OPTIONS = {
  xTicks: {every: 'hour', add: 4}
}

const CustomTooltip = ({ active, series, payload }: any) => {
  if (active && payload?.length && series?.length){
    const {timestamp, tradingPeriod, ...data} = payload[0].payload ?? {};
    return (
      <TooltipList>
        <TooltipHeader label={formatTimestamp(timestamp, {tradingPeriod})}/>
        { series.map((g, idx) => {
            const {label, color, strokeDasharray} = SERIES[g]
            return (
              <TooltipListItem
                key={idx}
                label={label}
                color={color}
                strokeDasharray={strokeDasharray}
                value={`${round(data[g])} MW`}
              />
          )})
        }
      </TooltipList>
    )
  }
}


const WindForecast = ({generationData, forecastData, width='100%', height='100%', zoomCallback, tooltips=[]}) => {

  const windData = useMemo(() => [
      ...(generationData?.items?.slice()?.reverse() || []),
      ...(forecastData?.items || [])
    ].map(({timestamp, percentile10, percentile90, ...rest}) => ({...rest, timestamp: moment(timestamp).valueOf(), percentile10, percentile90, percentileRange: [percentile10, percentile90]})),
  [JSON.stringify(generationData), JSON.stringify(forecastData)])

  const [graph, actions] = useSeries(SERIES, windData, {...SERIES_OPTIONS, zoomCallback})
  const firstOffered = graph.data.find((item) => !!item.offered)?.timestamp

  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          data={graph.data}
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            dataKey="timestamp"
            scale="time"
            type="number"
            domain={graph.xDomain}
            ticks={graph.xTicks}
            tickFormatter={tick => moment(tick).format('HH:mm')}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={graph.yDomain}
          />

          <Tooltip
            content={args => CustomTooltip({...args, series: graph.activeSeries})}
          />

          <defs>
            <linearGradient id="currentTimeGradient" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#B1E9E6" stopOpacity={0} />
              <stop offset="100%" stopColor="#B1E9E6" stopOpacity={0.18} />
            </linearGradient>
            <linearGradient id="percentileRangeGradient" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#B1E9E6" stopOpacity={0.3} />
              <stop offset="100%" stopColor="#B1E9E6" stopOpacity={0} />
            </linearGradient>
          </defs>

          <Area dataKey="percentileRange" fill="url(#percentileRangeGradient)" strokeWidth={0} />

          <ReferenceArea x2={firstOffered} fill="url(#currentTimeGradient)" />
          <ReferenceLine x={firstOffered} stroke="#fff"/>
          <ReferenceLine y={0} stroke="#fff"/>

          {graph.visibleSeries.map(g =>
            <Line
              key={g}
              type="linear"
              dataKey={g}
              stroke={SERIES[g].color}
              strokeDasharray={SERIES[g].strokeDasharray}
              animationDuration={300}
              dot={false}
              strokeWidth={2}
              activeDot={actions.isSeriesActive(g)}
            />
          )}

          {actions.renderZoomOverlay()}

          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3"/>
        </ComposedChart>
      </ResponsiveContainer>
      <InfoTip tooltips={tooltips}>
        Showing potential wind generation forecasts against offered generation from North Island wind farms
      </InfoTip>
      <Legend
        series={SERIES}
        visible={graph.visibleSeries}
        onSeriesToggle={actions.setVisibleSeries}
        tooltips={tooltips}
      />
    </>
  )

}

export default WindForecast