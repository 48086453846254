/** @jsxImportSource @emotion/react */
import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import Alert from '@mui/material/Alert'
import Scada from './data/scada.json'
import Price from './data/price.json'
import Reserves from './data/reserves.json'
import ReservePrices from './data/reserve_prices.js'
import Arc from './data/arc.json'
import Generation from './data/generation.js'
import Carbon from './data/carbon.json'
import Load from './data/load.json'
import Peaks from './data/peaks.json'

const reportMeta = ({reportType, emissionType, rcpdType, reserveReportType}) => {
  switch(reportType){
  case 'emissions':
    return emissionType === 'generation' ? Generation : Carbon
  case 'scada':
    return Scada
  case 'price':
    return Price
  case 'reserves':
    return reserveReportType === 'prices' ? ReservePrices : Reserves
  case 'arc':
    return Arc
  case 'rcpd':
    return rcpdType === 'load' ? Load : Peaks
  default:
    return {columns: []}
  }
}

const Report = ({data, filter, error={}}) => {

  if (!data){
    return null
  }

  const rows = data?.items || []
  const columns = reportMeta(filter).columns

  return (
    <div>
      { error?.message && <Alert css={styles.info} severity="error">{error.message}</Alert> }
      { !rows.length &&  <Alert css={styles.info} severity="info">No results found</Alert> }
      { data?.more && <Alert css={styles.info} severity="info">Found {data?.count} rows, previewing first {rows.length}</Alert> }
      { !!rows.length &&
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[10, 20, 30]}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
        /> }
    </div>)
}

const styles = {
  container: {
  },
  info: {
    marginBottom: 10
  }
}

export default Report