/** @jsxImportSource @emotion/react */
import React, {useMemo} from 'react'
import moment from 'moment'
import _ from 'lodash'
import { round } from 'utils'
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ReferenceArea,
} from 'recharts';
import { TooltipList, TooltipListItem, TooltipHeader, formatTimestamp } from './Tooltip'
import CustomReferenceLabel from './CustomReferenceLabel'
import Legend from './Legend'
import { useSeries } from 'hooks'

const NORTH_ISLAND_ZONE_IDS = [1,2,3,4,5,6,7,8]
const LOW_RESIDUAL_TRESHOLD = 200

const SERIES = {
  // NZ
  'NZ_NRS': {
    label: 'Nationwide NRS',
    color: '#e3c902',
    tooltip: "Combined long and short non-responsive schedule"
  },
  'NZ_NRS_windrisk': {
    label: 'Nationwide NRS minus Wind Risk',
    color: '#e3c902',
    strokeDasharray: "5 4",
  },
  'NZ_PRS': {
    label: 'Nationwide PRS',
    color: '#e6194B',
    tooltip: "Combined long and short price-responsive schedule"
  },
  'NZ_PRS_windrisk': {
    label: 'Nationwide PRS minus Wind Risk',
    color: '#e6194B',
    strokeDasharray: "5 4",
  },
  'NZ_WDS': {
    label: 'Nationwide WDS',
    color: '#ffd8b1',
    tooltip: "Week ahead dispatch schedule"
  },
  'NZ_WDS_windrisk': {
    label: 'Nationwide WDS minus Wind Risk',
    color: '#ffd8b1',
    strokeDasharray: "5 4",
  },
  'NZ_RTD': {
    label: 'Nationwide RTD',
    color: '#f58231',
    tooltip: "Real-time dispatch schedule"
  },
  // NI
  'NI_NRS': {
    label: 'North Island NRS',
    color: '#3cb44b',
    tooltip: "Combined long and short non-responsive schedule"
  },
  'NI_NRS_windrisk': {
    label: 'North Island NRS minus Wind Risk',
    color: '#3cb44b',
    strokeDasharray: "5 4",
  },
  'NI_NRS_HVDC': {
    label: 'North Island HVDC Residual',
    color: '#cd6155',
    tooltip: "Residual energy available for North Island via HVDC"
  },
  'NI_NRS_ISL': {
    label: 'North Island total residual inc. HVDC',
    color: '#f7dc6f',
    tooltip: "North Island Residual plus available HVDC Residual, combined long and short non-responsive schedule"
  },
  'NI_NRS_ISL_windrisk': {
    label: 'North Island total residual minus wind risk',
    color: '#f7dc6f',
    strokeDasharray: "5 4",
  },
  'NI_PRS': {
    label: 'North Island PRS',
    color: '#bfef45',
    tooltip: "Combined long and short price-responsive schedule"
  },
  'NI_PRS_windrisk': {
    label: 'North Island PRS minus Wind Risk',
    color: '#bfef45',
    strokeDasharray: "5 4",
  },
  'NI_WDS': {
    label: 'North Island WDS',
    color: '#469990',
    tooltip: "Week ahead dispatch schedule"
  },
  'NI_WDS_windrisk': {
    label: 'North Island WDS minus Wind Risk',
    color: '#469990',
    strokeDasharray: "5 4",
  },
  'NI_RTD': {
    label: 'North Island RTD',
    color: '#aaffc3',
    tooltip: "Real-time dispatch schedule"
  },
  // SI
  'SI_NRS': {
    label: 'South Island NRS',
    color: '#911eb4',
    tooltip: "Combined long and short non-responsive schedule"
  },
  'SI_NRS_HVDC': {
    label: 'South Island HVDC residual',
    color: '#61e4cd',
    tooltip: "Residual energy available for South Island via HVDC"
  },
  'SI_NRS_ISL': {
    label: 'South Island total residual inc. HVDC',
    color: '#0ba3e5',
    tooltip: "South Island Residual plus available HVDC Residual, combined long and short non-responsive schedule"
  },
  'SI_PRS': {
    label: 'South Island PRS',
    color: '#4363d8',
    tooltip: "Combined long and short price-responsive schedule"
  },
  'SI_RTD': {
    label: 'South Island RTD',
    color: '#42d4f4',
    tooltip: "Real-time dispatch schedule"
  },
  'SI_WDS': {
    label: 'South Island WDS',
    color: '#f032e6',
    tooltip: "Week ahead dispatch schedule"
  },
}

const SERIES_OPTIONS = {
  xTicks: {every: 'hour', add: 6}
}

const windForecastDataByTimestamp = ({windNodes, windForecast}) => {
  const capacity = (windNodes || []).reduce((acc: any, curr: any) => acc + (NORTH_ISLAND_ZONE_IDS.includes(curr.gridZoneId) ? curr.generationCapacityMw : 0), 0)
  const forecast = _.groupBy(windForecast?.items || [], 'timestamp')
  return {forecast, capacity}
}

// we only have forecast wind data for NI
const validWindRiskCombo = (region, runType) => (region === 'NI' || region === 'NZ') && ['NRS', 'PRS', 'WDS'].includes(runType)

const calculateWindRisk = (item, timestamp, windForecastData) => {
  const { residual, islandResidual, runType, region } = item
  if (validWindRiskCombo(region, runType)){
    const forecast = (windForecastData?.forecast || {})[timestamp]
    const offered = forecast && forecast[0]?.offered
    const capacity = windForecastData?.capacity
    const variableA = 7.66
    const variableB = 0.09

    if (offered && capacity){
      const windRisk = offered - Math.max(0, ((100 * offered / capacity) - variableA) / variableB)
      const data = {}
      if (residual)
        data[`${region}_${runType}_windrisk`] = residual - windRisk
      if (islandResidual)
        data[`${region}_${runType}_ISL_windrisk`] = islandResidual - windRisk
      return data
    }
  }
  return null
}

const CustomTooltip = ({ active, series, payload }: any) => {
  if (active && payload?.length && series?.length){
    const {timestamp, tradingPeriod, ...data} = payload[0].payload ?? {};
    return (
      <TooltipList>
        <TooltipHeader label={formatTimestamp(timestamp, {tradingPeriod})}/>
        { series.map((g, idx) => {
            const {label, color, strokeDasharray} = SERIES[g]
            return (
              <TooltipListItem
                key={idx}
                label={label}
                color={color}
                strokeDasharray={strokeDasharray}
                value={`${round(data[g])} MW`}
              />
          )})
        }
      </TooltipList>
    )
  }
}


const Residuals = ({data, windForecast, windNodes, width='100%', height='100%', series=null, zoomCallback, currentInterval=null, tooltips=[], children}: any) => {

  // reformat the API data to something we can chart
  const dataByTimestamp = _.groupBy(data?.items || [], 'timestamp')
  const windForecastData = windForecastDataByTimestamp({windNodes, windForecast})
  const selectedSeries = series ? _.pick(SERIES, series) : SERIES

  const residualsData = useMemo(() => Object.keys(dataByTimestamp).map(
    timestamp => ({
      timestamp: moment(timestamp).valueOf(),
      ...(dataByTimestamp[timestamp].reduce((acc: any, curr) => ({
        ...acc,
        tradingPeriod: curr.tradingPeriod,
        [`${curr.region}_${curr.runType}`]: curr.residual,
        [`${curr.region}_${curr.runType}_HVDC`]: curr.hvdcResidual,
        [`${curr.region}_${curr.runType}_ISL`]: curr.islandResidual,
        ...(calculateWindRisk(curr, timestamp, windForecastData) || {}),
      }), {}))
    })
  ), [JSON.stringify(data), JSON.stringify(windForecast), JSON.stringify(windNodes)])

  const [graph, actions] = useSeries(selectedSeries, residualsData, {...SERIES_OPTIONS, zoomCallback})
  let [yMin, yMax] = graph.yDomain
  yMin = Math.min(yMin, LOW_RESIDUAL_TRESHOLD - 100)
  const [xMin, xMax] = [graph.data[0]?.timestamp || 0, _.last(graph.data)?.timestamp || 0]
  const currentIntervalX = currentInterval && currentInterval.valueOf()

  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          data={graph.data}
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            dataKey="timestamp"
            scale="time"
            type="number"
            domain={graph.xDomain}
            ticks={graph.xTicks}
            tickFormatter={tick => moment(tick).format('HH:mm')}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={[yMin, yMax]}
          />

          <defs>
            <linearGradient id="currentIntervalGradient" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#B1E9E6" stopOpacity={0} />
              <stop offset="100%" stopColor="#B1E9E6" stopOpacity={0.4} />
            </linearGradient>
          </defs>

          <ReferenceLine
            y={LOW_RESIDUAL_TRESHOLD}
            stroke="white"
            strokeDasharray="5 4"
            strokeWidth={2}
            label={<CustomReferenceLabel fill="#fff" value={`${LOW_RESIDUAL_TRESHOLD} MW`}/>}
          />

          {currentIntervalX && (currentIntervalX > xMin) && (currentIntervalX < xMax) &&
            <>
              <ReferenceArea x2={currentIntervalX} fill="url(#currentIntervalGradient)" />
              <ReferenceLine
                x={currentIntervalX}
                stroke="white"
              />
            </>
          }

          <Tooltip
            content={args => CustomTooltip({...args, series: graph.activeSeries})}
          />

          {graph.visibleSeries.map(g =>
            <Line
              key={g}
              type="linear"
              dataKey={g}
              stroke={SERIES[g].color}
              strokeDasharray={SERIES[g].strokeDasharray}
              animationDuration={300}
              dot={false}
              strokeWidth={2}
              activeDot={actions.isSeriesActive(g)}
            />
          )}

          {actions.renderZoomOverlay()}

          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3"/>
        </LineChart>
      </ResponsiveContainer>
      {children}
      <Legend
        series={selectedSeries}
        visible={graph.visibleSeries}
        onSeriesToggle={actions.setVisibleSeries}
        tooltips={tooltips}
      />
    </>
  )

}

export default Residuals