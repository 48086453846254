/** @jsxImportSource @emotion/react */
import React from 'react'
import MultiSelect from 'components/MultiSelect'
import Grid from '@mui/material/Grid'
import _ from 'lodash'

export const REGIONS = [
  {id: 'NI', label: 'North Island'},
  {id: 'SI', label: 'South Island'},
]

export const SERIES = [
  {id: 'isl', label: 'Island Residual'},
  {id: 'hvdc', label: 'HVDC Residual'},
  {id: 'wr', label: 'Wind Risk'},
]

const IslandResidualsFilter = ({filter, onChange}) => {

  // Wind risk available for NSR North Island, Residual and Island Residual
  const niSelected = (filter.regions || []).includes('NI')
  const windRiskEnabled = niSelected && _.intersection(filter.series || [], ['isl', 'res']).length

  const isSeriesDisabled = s => s === 'wr' && !windRiskEnabled
  const seriesWithStatus = SERIES.map(s => ({...s, disabled: isSeriesDisabled(s.id)}))
  const selectedSeries = (filter.series || []).filter(s => !isSeriesDisabled(s))

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <MultiSelect
          value={filter.regions}
          label="Region"
          allSelectedLabel="North & South Island"
          noneSelectedLabel="None"
          options={REGIONS}
          onChange={regions => onChange({...filter, regions})}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MultiSelect
          value={selectedSeries}
          label="Series"
          allSelectedLabel="All Series"
          noneSelectedLabel="None"
          options={seriesWithStatus}
          onChange={series => onChange({...filter, series})}
          fullWidth
        />
      </Grid>
    </Grid>
  )

}


export default IslandResidualsFilter