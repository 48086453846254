import moment from 'moment'

const report = {
  columns: [
    {
      field: "month",
      headerName: "Month",
      flex: 1,
      valueGetter: (value) => moment(value).format('MMMM YYYY'),
    },
    {
      field: "generationTypeName",
      headerName: "Generation Type",
      flex: 1
    },
    {
      field: "generationMw",
      headerName: "Generation (MW)",
      type: "number",
      flex: 1
    },
    {
      field: "generationCarbonT",
      headerName: "Carbon (T)",
      type: "number",
      flex: 1
    }
  ]
}

export default report
