/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PersonIcon from '@mui/icons-material/Person'
import HomeIcon from '@mui/icons-material/Home';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import AssessmentIcon from '@mui/icons-material/Assessment';
import WindPowerIcon from '@mui/icons-material/WindPower';
import LocalMallSharpIcon from '@mui/icons-material/LocalMallSharp';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button'
import { useTokens } from 'contexts/TokensContext';
import { useAuthorization, useVersion, useScreenInfo } from 'hooks';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { StylesObject } from 'types'
import { CurrentTradingPeriod, EnvironmentLabel } from 'components'


const Nav = () => {
  const [version] = useVersion()
  const history = useHistory()
  const theme = useTheme()
  const { isMobile, isFoyer } = useScreenInfo()

  return (
    <>
      <MuiAppBar position="sticky">
        <Toolbar>
          <div css={styles.head}>
            <Link to='/' css={styles.home}>
              <img src="/logo.svg" alt="EM6" css={styles.logo} />
              {!isMobile && <Typography css={styles.title} variant="h5">Electricity Market Overview</Typography>}
            </Link>
          </div>
          {!isMobile && <EnvironmentLabel/>}
          {!isMobile && <CurrentTradingPeriod/>}
          {!isFoyer && <div css={styles.actions}>
            <Button css={styles.signIn} size="small" onClick={() => history.push('/tokens/sign_in')} variant="contained">
              Sign In
            </Button>
          </div>}
        </Toolbar>
      </MuiAppBar>
    </>
  );
}

const styles = {
  head: {
    width: '100%',
  },
  home: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600
  },
  actions: {
    '& button':{
      textWrap: 'nowrap',
      margin: 0,
    }
  },
  logo: {
    margin: '10px 40px 18px 20px',
  },
  version: {
    position: 'absolute',
    bottom: 0,
    right: 10,
    fontSize: 11,
    color: "#9a9a9a"
  },
  signIn: {
  }
} as StylesObject

export default Nav
