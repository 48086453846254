const report = {
  columns: [
    {
      field: "region",
      headerName: "Region",
      flex: 1
    },
    {
      field: "runType",
      headerName: "Run Type",
      flex: 1
    },
    {
      field: "tradingDate",
      headerName: "Trading Date",
      flex: 1,
      type: "date",
      valueGetter: (value) => new Date(value),
    },
    {
      field: "tradingPeriod",
      headerName: "Trading Period",
      type: "number",
      flex: 1
    },
    {
      field: "timestamp",
      headerName: "Time",
      flex: 1,
      type: "dateTime",
      valueGetter: (value) => new Date(value),
    },
    {
      field: "sirPrice",
      headerName: "SIR ($)",
      type: "number",
      flex: 1
    },
    {
      field: "firPrice",
      headerName: "FIR ($)",
      type: "number",
      flex: 1
    }
  ]
}

export default report


