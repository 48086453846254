/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import CircularProgress from '@mui/material/CircularProgress'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Avatar from '@mui/material/Avatar'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import InfoIcon from '@mui/icons-material/Info'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SaveIcon from '@mui/icons-material/Save'
import RefreshIcon from '@mui/icons-material/Refresh'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { formatLocaleDateTime } from 'utils'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useSnackbar} from 'contexts/SnackbarContext'
import {useFavourites} from 'contexts/FavouritesContext'
import {useTokens} from "contexts/TokensContext";
import _ from 'lodash'
import moment from 'moment'
import {SessionStatus} from 'constants'

//
// Render widget inside a MUI Card with title, zoom button, filters & action menu
// Handles some generic actions like adding to favourites, zooming out, show/hide filters, full screen toggle etc.
//

const WidgetContainer = ({
  widget,
  title,
  icon=null,
  lastUpdatedAt,
  lastUpdatedAtAddMinutes=30, // timestamp for graphs is often the start of each trading period, giving the impression the graph is 30min older than it actually is
  loading,
  renderFilter=null,
  renderActions=null,
  onWidgetRefresh,
  onFilterSave,
  zoom,
  favourite=false,
  fullScreen=false,
  toggleFullScreen=null,
  widgetAutoRefreshMs=null,
  children,
  tooltips=[],
  hideActions=false,
  showAttribution=false,
}) => {

  const [menuAnchor, setMenuAnchor] = useState(null)
  const { widgets, addWidget, removeWidget, moveWidgetLeft, moveWidgetRight } = useFavourites()
  const [hideFilter, setHideFilter] = useState(!!widget?.hideFilter)
  const snackbar = useSnackbar()
  const avatar = loading ? <CircularProgress thickness={5} size={40} css={styles.spinner}/> : (icon || <EqualizerIcon/>)
  const addedToFavourites = widgets.find(({id}) => id === widget.id)
  const lastUpdatedAtTimestamp = lastUpdatedAt && (lastUpdatedAtAddMinutes ? moment(lastUpdatedAt).add(lastUpdatedAtAddMinutes, 'minutes') : lastUpdatedAt)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const [tokens, tokenActions] = useTokens()
  const authenticated = tokens.loginState === SessionStatus.AUTHENTICATED
  const [tooltipsEnabled, setTooltips] = tooltips

  // widget auto refresh
  useEffect(() => {
    let _timer = null
    // for local development or testing it can be usefull to disable all widget auto refresh
    const disabled = process.env.REACT_APP_DISABLE_WIDGET_AUTO_REFRESH
    if (!disabled && widgetAutoRefreshMs){
      _timer = setInterval(onWidgetRefresh, widgetAutoRefreshMs)
    }
    return () => _timer && clearInterval(_timer)
  }, [widgetAutoRefreshMs])

  const handleUpdateFavourites = () => {
    if (addedToFavourites){
      removeWidget(widget)
      snackbar.show(`${widget.name} removed from favourites`)
    } else {
      addWidget(widget)
      snackbar.show(`${widget.name} added to favourites`)
    }
  }

  const menuItems = _.compact([
    setTooltips && {label: `${tooltipsEnabled ? 'Hide' : 'Show'} tooltips`, icon: <InfoIcon/>, onClick: () => setTooltips(!tooltipsEnabled)},
    authenticated && (isMobile ? {label: addedToFavourites ? 'Remove from favourites' : 'Add to favourites', icon: addedToFavourites ? <FavoriteIcon/> : <FavoriteBorderOutlinedIcon/>, onClick: handleUpdateFavourites} : null),
    onWidgetRefresh ? {label: `Refresh data`, icon: <RefreshIcon/>, onClick: onWidgetRefresh} : null,
    onFilterSave && {label: `Save filters as default`, icon: <SaveIcon/>, onClick: onFilterSave},
    favourite ? {label: 'Move left', icon: <ChevronLeftIcon/>, onClick: () => moveWidgetLeft(widget)} : null,
    favourite ? {label: 'Move right', icon: <ChevronRightIcon/>, onClick: () => moveWidgetRight(widget)} : null,
  ])

  const handleMenuItemClick = item => () => {
    item.onClick && item.onClick()
    setMenuAnchor(null)
  }

  const renderFavouriteAction = () => {
    const icon = addedToFavourites ? <FavoriteIcon/> : <FavoriteBorderOutlinedIcon/>
    const tooltip = addedToFavourites ? 'Remove from favourites' : 'Add to favourites'

    return (
      <Tooltip title={tooltip} placement="top">
        <IconButton aria-label="favourites" onClick={handleUpdateFavourites}>
          {icon}
        </IconButton>
      </Tooltip>
    )
  }

  const renderZoomAction = () => {
    const { zoomed, zoomOut } = zoom
    const zoomInstruction = widget.id.includes('map') ? 'Double click on area to zoom, drag to pan' : 'Drag an area on the chart to zoom'
    const tooltip = zoomed ? 'Zoom out' : zoomInstruction

    return (
      <Tooltip title={tooltip} placement="top">
        <span>
        <IconButton disabled={!zoomed} aria-label="zoom" onClick={zoomOut}>
          <ZoomOutIcon/>
        </IconButton>
        </span>
      </Tooltip>
    )
  }

  const renderToggleFilterAction = () => {
    const tooltip = hideFilter ? 'Show filters' : 'Hide filters'

    return (
      <Tooltip title={tooltip} placement="top">
        <span>
        <IconButton aria-label="filter" onClick={() => setHideFilter(!hideFilter)}>
          <FilterAltIcon style={{color: hideFilter ? '#D5D5D5' : '#fff'}}/>
        </IconButton>
        </span>
      </Tooltip>
    )
  }

  const renderFullScreenAction = () => {
    const tooltip = fullScreen ? 'Close full size' : 'Open full size'

    return (
      <Tooltip title={tooltip} placement="top">
        <span>
        <IconButton aria-label="filter" onClick={toggleFullScreen}>
          {fullScreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
        </IconButton>
        </span>
      </Tooltip>
    )
  }

  return (
    <Card className='widget-container' css={styles.container}>
      <CardContent css={styles.content}>
        <CardHeader
          avatar={<Avatar css={styles.avatar}>{avatar}</Avatar>}
          title={title}
          subheader={lastUpdatedAt && `Last updated ${formatLocaleDateTime(lastUpdatedAtTimestamp)}`}
          action={!hideActions &&
            <div>
              {!isMobile && renderFilter && renderToggleFilterAction() }
              {!isMobile && zoom && renderZoomAction()}
              {!isMobile && toggleFullScreen && renderFullScreenAction()}
              {authenticated && !isMobile && renderFavouriteAction()}
              <IconButton aria-label="graph-options" onClick={event => setMenuAnchor(event.currentTarget)}>
                <MoreVertIcon />
              </IconButton>
            </div>}
        />

        {menuItems &&
          <Menu
            id="graph-options"
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={() => setMenuAnchor(null)}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            css={styles.menu}
          >
            {menuItems.map((item, idx) =>
              <MenuItem key={idx} onClick={handleMenuItemClick(item)}>{item.icon} {item.label}</MenuItem>)}
          </Menu>}

        <div css={styles.graph} className='graph' style={fullScreen ? styles.fullScreen : {}}>
          { !hideActions && renderFilter && !hideFilter && <div css={styles.filter}>{ renderFilter() }</div> }
          { children }
        </div>
        {showAttribution && <a href="https://em6.co.nz/"><img src="/logo_small.svg" alt="EM6" css={styles.logo}/></a>}
      </CardContent>
      {renderActions && <CardActions css={styles.actions}>{renderActions()}</CardActions>}
    </Card>)
}

const styles = {
  container: {
    backgroundColor: '#003b59',
    height: '100%',
    width: '100%',
    '& .MuiCardHeader-title': {
      fontSize: '1.1rem',
      fontWeight: 800,
    }
  },
  avatar: {
    color: '#00A9A5',
    backgroundColor: 'rgba(0,32,48,.5019607843137255)'
  },
  content: {
    padding: 10,
    maxWidth: 'none',
  },
  actions: {
    padding: 10,
    paddingRight: 20,
    float: 'right'
  },
  spinner: {
    color: '#00A9A5'
  },
  menu: {
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      marginRight: 12,
    },
  },
  graph: {
    height: 'unset',
    aspectRatio: '3/2 auto',
  },
  fullScreen: {
    aspectRatio: 'unset',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 160px)',
    '& .recharts-responsive-container': {
      flex: 1
    }
  },
  filter: {
    padding: 15,
    marginTop: 10,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 0,
        borderRadius: 0,
        borderBottom: '1px solid #4d4d4d'
      },
    }
  },
  logo: {
    height: 15,
    width: 80,
  },
}

export default WidgetContainer