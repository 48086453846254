/** @jsxImportSource @emotion/react */
import React from 'react'
import { Marker as SimpleMapsMarker, Annotation } from "react-simple-maps"

export const ANNOTATION_PADDING = 5
export const HEADER_LINE_HEIGHT = 14
export const VALUE_LINE_HEIGHT = 18
export const CORNERS_RADIUS = 6

export const DropShadow = () =>
  <filter id="dropshadow">
    <feDropShadow dx="0" dy="0" stdDeviation="2" floodColor="#000" floodOpacity="0.4" />
  </filter>


const Marker = ({
    coordinates, dx=0, dy=0, title, text, visible=true, labelStyle={}, markerStyle={},
    connectorStyle={}, textStyle={}, icon=null, onMouseOver=null, height=null, width=null,
    titleFontSize=12, valueFontSize=16, padding=ANNOTATION_PADDING,
    children=null
  }) => {

  const markerWidth  = width || 100
  const markerHeight = height || (2 * padding + HEADER_LINE_HEIGHT + VALUE_LINE_HEIGHT)
  const textMarginX  = 1.2  * padding + (icon ? 20 : 0)
  const textValueY = padding + HEADER_LINE_HEIGHT + padding

  return ( visible ?
    <>
      {/* label and connector */}
      <Annotation
        subject={coordinates}
        dx={dx}
        dy={dy}
        onMouseOver={onMouseOver}
        connectorProps={{
          stroke: '#fff',
          strokeWidth: 1,
          ...connectorStyle,
        }}
      >
        <g transform={`translate(${-2 * padding}, ${-(markerHeight / 2)})`}>
          {/* background box */}
          <rect
            x={0}
            y={0}
            width={markerWidth}
            height={markerHeight}
            rx={CORNERS_RADIUS}
            filter="url(#dropshadow)"
            {...labelStyle}
          />
          {children}

          <g transform={`translate(${padding}, ${2 * padding})`}>
            {/* MUI icon must have property inheritViewBox={true} */}
            {icon}
            <text
              css={styles.text}
              fontSize={titleFontSize}
              fill="#fff"
              x={textMarginX}
              y={padding}
              {...textStyle}
            >
              {title}
            </text>

            <text
              css={styles.text}
              fontSize={valueFontSize}
              fill="#fff"
              fontWeight="bold"
              x={textMarginX}
              y={textValueY}
              {...textStyle}
            >
              {text}
            </text>
          </g>
        </g>

      </Annotation>

      {/* dot on the map  */}
      <SimpleMapsMarker coordinates={coordinates} id={title}>
        <circle r={6} fill="#fff" stroke="#E42A1D" strokeWidth={4} {...markerStyle} />
      </SimpleMapsMarker>
    </> : null
  )
}

const styles = {
  text: {
    cursor: 'default',
  },
  icon: {
    float: 'left',
    '& .MuiSvgIcon-root': {
      marginTop: 12,
      marginRight: 10,
      strokeWidth: 0,
    }
  }
}

export default Marker