import {useState} from 'react'
import {useWidgetPreferences} from 'contexts/WidgetPreferencesContext'

// initial state of the tooltips is loaded from local storage
const useTooltips = (widget, initalState=false) => {
  const preferences = useWidgetPreferences()
  const [enabled, setEnabled] = useState(preferences.load(widget)?.tooltips ?? initalState)

  const update = status => {
    setEnabled(status)
    preferences.update(widget, {tooltips: status})
  }

  return [enabled, update]
}

export default useTooltips