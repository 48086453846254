import { ProtectedJsonAPI } from './ProtectedJsonAPI'
import { filterQueryString } from 'utils'

const MAX_PREVIEW_RESULTS = 200

const reportURL = (path, csv, params) => {
  return `/${path}${csv ? '_csv': ''}${filterQueryString(params)}`
}

//
// URL with query params for each report type
// Not every filter applies to each report (sub) type (the API crashes) so it's a bit of work...
//

const scadaURL = ({csv=false, ...filter}) => {
  const { scadaType, nodes, from: from_trading_date, to: to_trading_date } = filter
  switch(scadaType){
  case 'load':
    return reportURL('scada_load', csv, { nodes, from_trading_date, to_trading_date })
  case 'generation':
    return reportURL('scada_generation', csv, { nodes, from_trading_date, to_trading_date })
  case 'hvdc':
    return reportURL('scada_hvdc', csv, { from_trading_date, to_trading_date })
  default:
    return null
  }
}

const priceURL = ({csv=false, ...filter}) => {
  const { priceType, nodes, from: from_trading_date, to: to_trading_date, priceRunType: run_type } = filter
  switch(priceType){
  case 'average':
    return reportURL('average_price', csv, { nodes, from_trading_date, to_trading_date })
  case 'market':
    return reportURL('market_price', csv, { nodes, trading_date: from_trading_date, run_type })
  default:
    return null
  }
}

const reservesURL = ({csv=false, ...filter}) => {
  const { reserveReportType, location: island, powerType: power_type, reserveRunType: run_type, reserveType: reserve_type, from: from_trading_date, to: to_trading_date } = filter
  switch(reserveReportType){
  case 'aggregated':
    return reportURL('reserves_agg', csv, { from_trading_date, to_trading_date, island, power_type, reserve_type })
  case 'source':
    return reportURL('res_amount_source', csv, { from_trading_date, to_trading_date, island, power_type, reserve_type })
  case 'extract':
    return reportURL('reserves_extract', csv, { from_trading_date, to_trading_date, island, power_type, reserve_type })
  case 'prices':
    return reportURL('reserve_prices', csv, { from_trading_date, to_trading_date, region: island, reserve_type, run_type })
  default:
    return null
  }
}

const arcURL = ({csv=false, ...filter}) => {
  const { arcType: run_type, from: trading_date } = filter
  return reportURL('arc_flows', csv, { trading_date, run_type })
}

const emissionsURL = ({csv=false, ...filter}) => {
  const { emissionType, generationTypes: generation_type_id, from: from_trading_date, to: to_trading_date } = filter
  switch(emissionType){
  case 'carbon':
    return reportURL('carbon_intensity', csv, { from_trading_date, to_trading_date })
  case 'generation':
    return reportURL('historic_generation_type', csv, { generation_type_id, from_trading_date, to_trading_date })
  default:
    return null
  }
}

const rcpdURL = ({csv=false, ...filter}) => {
  const { rcpdType, year: cm_year, region } = filter
  switch(rcpdType){
  case 'load':
    return reportURL('rcpd_load', csv, { region })
  case 'peaks':
    return reportURL('rcpd_peaks', csv, { region, cm_year })
  default:
    return null
  }
}

// DataGrid requires unique ID's for the report preview to work
// We only render the first 200 results (and a warning)
const previewWithID = items => ({
  items: items.slice(0, MAX_PREVIEW_RESULTS).map((item, idx) => ({id: idx, ...item})),
  count: items.length,
  more: items.length > MAX_PREVIEW_RESULTS,
})

export const Report = ProtectedJsonAPI.extend(
  '',
  {
    scada: endpoint => endpoint.path(filter => {
      return scadaURL(filter)
    }).resultsHandler(data => previewWithID(data.items)),

    scada_csv: endpoint => endpoint.type('blob').path(filter => {
      return scadaURL({csv: true, ...filter})
    }),

    price: endpoint => endpoint.path(filter => {
      return priceURL(filter)
    }).resultsHandler(data => previewWithID(data.nodePrices || data.items)),

    price_csv: endpoint => endpoint.type('blob').path(filter => {
      return priceURL({csv: true, ...filter})
    }),

    reserves: endpoint => endpoint.path(filter => {
      return reservesURL(filter)
    }).resultsHandler(data => previewWithID(data.items)),

    reserves_csv: endpoint => endpoint.type('blob').path(filter => {
      return reservesURL({csv: true, ...filter})
    }),

    arc: endpoint => endpoint.path(filter => {
      return arcURL(filter)
    }).resultsHandler(data => previewWithID(data.items)),

    arc_csv: endpoint => endpoint.type('blob').path(filter => {
      return arcURL({csv: true, ...filter})
    }),

    emissions: endpoint => endpoint.path(filter => {
      return emissionsURL(filter)
    }).resultsHandler(data => previewWithID(data.monthlyGen?.map(({month, generationType}) => generationType.map(g => ({...g, month})))?.flat() || data.items)),

    emissions_csv: endpoint => endpoint.type('blob').path(filter => {
      return emissionsURL({csv: true, ...filter})
    }),

    rcpd: endpoint => endpoint.path(filter => {
      return rcpdURL(filter)
    }).resultsHandler(data => {
      if (data.yesterday && data.today){
        return previewWithID([...data.yesterday, ...data.today])
      } else {
        return previewWithID(data.items)
      }
    }),

    rcpd_csv: endpoint => endpoint.type('blob').path(filter => {
      return rcpdURL({csv: true, ...filter})
    }),
  }
)
